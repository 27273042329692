exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-books-jsx": () => import("./../../../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-credits-jsx": () => import("./../../../src/pages/credits.jsx" /* webpackChunkName: "component---src-pages-credits-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pm-course-jsx": () => import("./../../../src/pages/pm-course.jsx" /* webpackChunkName: "component---src-pages-pm-course-jsx" */),
  "component---src-pages-pm-quiz-jsx": () => import("./../../../src/pages/pm-quiz.jsx" /* webpackChunkName: "component---src-pages-pm-quiz-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-download-jsx": () => import("./../../../src/templates/download.jsx" /* webpackChunkName: "component---src-templates-download-jsx" */)
}

